import { TYPE } from "vue-toastification";
import { apiCall, apiCallParams, logResponse } from "@/core/api";
import { showAlert } from "@/core/popup";

export async function getConnectorSpecs(): Promise<any> {
  const resource = "/specs";
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getConnectorList(): Promise<any> {
  const resource = "/connectors";
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getConnectorListFromMaster():Promise<any> {
  const resource = "/connectors/master";
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function findConnectorsRelatedWithTenant(tenantName):Promise<any> {
  const resource = `/tenants/connectors/${tenantName}`;
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getConnector(connectorId: string): Promise<any> {
  const endpoint = `/connectors/${connectorId}`;
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result[0];
  }
  logResponse(response);
  return undefined;
}

export async function deleteConnector(connectorId: string): Promise<boolean> {
  const endpoint = `/connectors/${connectorId}`;
  const response = await apiCall("DELETE", endpoint);
  if (response.code === 200) {
    return true;
  }
  logResponse(response);
  return false;
}

export async function addConnector(connector: any): Promise<any> {
  const endpoint = "/connectors";
  const response = await apiCall("POST", endpoint, connector);
  if (response.code === 200) {
    return response.result;
  }
  if (response.code === 406) {
    showAlert(TYPE.WARNING, "Connector with the same name exists.");
    return undefined;
  }
  logResponse(response);
  return undefined;
}

export async function editConnector(connector: any): Promise<any> {
  const endpoint = "/connectors";
  const response = await apiCall("PUT", endpoint, connector);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getConnectorListing(params: any):Promise<any> {
  const endpoint = "/connectors/organization-list";
  const response = await apiCallParams("GET", endpoint, params);
  if (response.code === 200) {
    return response.page;
  }
  logResponse(response);
  return undefined;
}
